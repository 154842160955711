<template>
  <div id="app">
      <router-view/>
    <notifications group="foo" />
  </div>
</template>

<script>


export default {
  name: 'App',
  data(){
    return{}
  },
  methods:{
    async getLocalizedValues(){
      try {
        const res = await this.$store.dispatch('getValuesByLang');
        this.$store.commit('changeLocale', res.result);
      } catch (e) {
        this.errorNotify(e.response?.data?.error?.message ?? e);
      }
    },
    async getLocalizedValuesBack(){
      try {
        const res = await this.$store.dispatch('getValuesBack');
        this.$store.commit('changeLocaleBack', res.result);
      } catch (e) {
        this.errorNotify(e.response?.data?.error?.message ?? e);
      }
    }
  },
  async created() {
    await this.getLocalizedValues();
    await this.getLocalizedValuesBack()
  }
}
</script>

<style>

</style>
