export default {
    data(){
      return{
          body:{
              pageNumber:0,
              pageSize:0
          },
          deleteDisabledList:[
              {
                  keyword: "big_farm_id",
                  id: 2,
              },
              {
                  keyword: "calves_milk_id",
                  id: 1,
              },
              {
                  keyword: "client_payment_inexp_category_id",
                  id: 1,
              },
              {
                  keyword: "cow_sale_inexp_category_id",
                  id: 3,
              },
              {
                  keyword: "milk_product_id",
                  id: 1,
              },
              {
                  keyword: "milk_sale_inexp_category_id",
                  id: 5,
              },
              {
                  keyword: "need_to_import_data",
                  id: 1234,
              },
              {
                  keyword: "salary_payment_inexp_category_id",
                  id: 2,
              },
              {
                  keyword: "sheep_sale_inexp_category_id",
                  id: 4,
              },
              {
                  keyword: "small_farm_id",
                  id: 1,
              }
          ]
      }
    },
    methods: {
        // inputNumberType(value){
        //     if (value){
        //       return value.replace(/\D/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        //   }
        //     return value
        // },
        showLoader() {
            this.$store.commit('loader', true);
        },
        hideLoader() {
            this.$store.commit('loader', false);
        },
        getNameById(list = [], id,  keyName = 'name') {
            if (list.length > 0) {
                let item = list.find(x => x.id === id);
                return item ?  item[keyName] : id;
            }
        },
        getSelectList(url,list = []){
            let body={
                 pageNumber:0,
                 pageSize:0
            }
            this.$http.post(url, body).then(res =>{
                list.push(...res.result.data);
            })
                .catch(e=>{
                    this.errorNotify(this.contentLocaleBack[e?.response?.data?.error?.message?.toLowerCase()])
                })
        },
        isDeleteDisableBtn(id){
            let item = this.deleteDisabledList.find(x => x.id === id);
            return item ? true : false
        },
        formatMoney(value) {
            value = String(value).replace(/[^0-9.-]/g, '');
            const parts = value.split('.');
            const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            return parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;
        },
        // formatMoney(value) {
        //     // Remove any non-numeric characters except for '.' and '-'
        //     value = String(value).replace(/[^0-9.-]/g, '');
        //
        //     // Split the value into integer and decimal parts
        //     const parts = value.split('.');
        //
        //     // Handle the case where '-' is at the beginning
        //     let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        //
        //     // Check if the value is negative and add '-' to the formatted integer part
        //     if (value.charAt(0) === '-' && integerPart.charAt(0) !== '-') {
        //         integerPart = `-${integerPart}`;
        //     }
        //
        //     // Join the integer and decimal parts
        //     return parts.length > 1 ? `${integerPart}.${parts.slice(1).join('.')}` : integerPart;
        // }



    },
    computed:{
        contentLocale(){
            return this.$store.getters.getContentLocale
        },
        contentLocaleBack(){
            return this.$store.getters.getContentLocaleBack
        },
    }
}
export const inputNumberMixin = {
  methods: {
    inputNumberType(value) {
      if (value) {
        return String(value).replace(/[^\d.]/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
      }
      return value;
    }
  }
};
