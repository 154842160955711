<template>
  <div>
    <el-card shadow="never" class="border-radius-12">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h5 class="color-black font-weight-700">{{ contentLocale.employees }}</h5>
        <el-button v-if="can('employees.upsertemployee')" class="add_btn mx-1" @click="addShowModal" icon="el-icon-circle-plus-outline">{{ contentLocale.add }}</el-button>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="11" :md="6" :lg="3" :xl="3" class="mb-2">
            <el-select v-model="paginationList.farmId" filterable collapse-tags multiple :placeholder="contentLocale.farm" class="w-100 mx-1">
              <el-option
                  v-for="item in farms "
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :hidden="item.isDeleted"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="11" :md="6" :lg="3" :xl="3" class="mb-2">
            <el-date-picker v-model="paginationList.from" class="w-100 mx-1" :placeholder="contentLocale.fromdate" :clearable="false"></el-date-picker>
          </el-col>
          <el-col :xs="24" :sm="11" :md="6" :lg="3" :xl="3" class="mb-2">
            <el-date-picker v-model="paginationList.to" class="w-100 mx-1" :placeholder="contentLocale.todate" :clearable="false"></el-date-picker>
          </el-col>
          <el-col :xs="24" :sm="11" :md="6" :lg="4" :xl="3" class="mb-2">
            <el-date-picker
                @change="getBirthdate"
                class="w-100 mx-1"
                v-model="birthdateMassive"
                type="daterange"
                :start-placeholder="contentLocale.from"
                :end-placeholder="contentLocale.to"
                size="default"
                :clearable="false"
                :picker-options="{
                  disabledDate(date) { return date > new Date() }
                }"
            />
          </el-col>
          <el-col :xs="24" :sm="11" :md="6" :lg="3" :xl="3" class="mb-2">
            <el-select v-model="paginationList.positionIds" filterable collapse-tags multiple :placeholder="contentLocale.position" class="w-100 mx-1">
              <el-option
                  v-for="item in positionList "
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :hidden="item.isDeleted"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="11" :md="6" :lg="3" :xl="3" class="mb-2">
            <el-input @input="exSalaryFrom = inputNumberType(exSalaryFrom)" v-model="exSalaryFrom" :placeholder="contentLocale.placeholdersalaryfrom" class="w-100 mx-1"></el-input>
          </el-col>
          <el-col :xs="24" :sm="11" :md="6" :lg="3" :xl="3" class="mb-2">
            <el-input @input="exSalaryTo = inputNumberType(exSalaryTo)" v-model="exSalaryTo" :placeholder="contentLocale.pleaceholdersalaryto" class="w-100 mx-1"></el-input>
          </el-col>
          <el-col :xs="24" :sm="11" :md="6" :lg="3" :xl="3" class="mb-2 " >
            <el-button v-if="isSearch" @click="resetSearchList()" type="warning" class="mx-1">{{ contentLocale.resetsearch }}</el-button>
            <el-button v-else @click="searchList()" type="primary">{{ contentLocale.search }}</el-button>
          </el-col>
        </el-row>
      </div>
      <el-table
          :data="EmployeesGroupList"
          style="width: 100%; color: #fff; margin-bottom: 20px"
          :row-class-name="tableRowClassName"
          v-loading="isTableLoading"
      >
        <el-table-column
             label="№"
             type="index"
        >
          <template slot-scope="scope">
            {{scope.$index + ((paginationList.pageNumber-1) * paginationList.pageSize) + 1}}
          </template>
        </el-table-column>
        <el-table-column
          prop="firstName" :label="contentLocale.firstname"
        >
        </el-table-column>
        <el-table-column
          prop="lastName" :label="contentLocale.lastname"
        >
        </el-table-column>
        <el-table-column
          prop="middleName" :label="contentLocale.middlename"
        >
        </el-table-column>
        <el-table-column
            prop="farmName" :label="contentLocale.farm"
        >
        </el-table-column>
        <el-table-column
          prop="positionName" :label="contentLocale.position"
        >
        </el-table-column>
        <el-table-column
          prop="employmentDate" :label="contentLocale.employmentdate"
        >
          <template slot-scope="scope">
            <span>{{scope.row.employmentDate | commonDateFormatYear}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="salaryByState" :label="contentLocale.salarybystate"
        >
          <template slot-scope="scope">
            <span>{{scope.row.salaryByState | financialFormatMoney}}</span>
          </template>
        </el-table-column>
        <el-table-column
            :label="contentLocale.action">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.isDeleted && can('employees.upsertemployee')" type="primary"
                       icon="el-icon-edit" circle plain @click="showEditModal(scope.row.id)"
            ></el-button>
            <el-button v-if="!scope.row.isDeleted && can('employees.deleteemployee')" type="danger"
                       icon="el-icon-delete" circle plain @click="showDeleteModal(scope.row.id,scope.row)"
            ></el-button>
            <div v-if="scope.row.isDeleted" class="delete__effect" ><span>{{ contentLocale.deleted }}</span></div>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="pagination_row" v-if="totalCount > 0">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="paginationList.pageNumber"
            :page-size= "paginationList.pageSize"
            :page-sizes="[10,30,50,totalCount]"
            layout="total, sizes, prev, pager, next, jumper"
            :total = "totalCount"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog
      :title="isUpdate ? `${contentLocale.edit}` : `${contentLocale.adding}`"
      :visible="upsertModal"
      width="33%"
      @close="upsertModal= false"
      :close-on-click-modal="true"
      :show-close="false"
      class="my-modal"
    >
        <div class="modal-content">
      <el-form hide-required-asterisk :model="postEmployees " :rules="formRules" ref="myForm" label-width="120px"
               class="form-group my-form" label-position="top">
        <el-row>
          <el-col :md="12">
            <el-form-item prop="farmId" class="px-1 mb-3" :label="contentLocale.farm">
              <el-select  v-model="postEmployees.farmId" :placeholder="contentLocale.farm" class="w-100">
                <el-option
                    v-for="item in farms "
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    :hidden="item.isDeleted"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="firstName" class="px-1 mb-3" :label="contentLocale.firstname">
              <el-input  v-model="postEmployees.firstName" :placeholder="contentLocale.pleaceholdername" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="lastName" class="px-1 mb-3" :label="contentLocale.lastname">
              <el-input  v-model="postEmployees.lastName" :placeholder="contentLocale.placeholderlastname" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="middleName" class="px-1 mb-3" :label="contentLocale.middlename">
              <el-input  v-model="postEmployees.middleName" :placeholder="contentLocale.placeholdermiddlename" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item  class="px-1 mb-3" :label="contentLocale.address">
              <el-input  v-model="postEmployees.address" :placeholder="contentLocale.plaesholderadress" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item  class="px-1 mb-3" :label="contentLocale.phonenumber">
              <el-input v-model="postEmployees.phoneNumber"  v-mask="'+### ## ### ## ##'" placeholder="+998" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item  class="px-1 mb-3" :label="contentLocale.pnfl">
              <el-input  v-model="postEmployees.pinfl" :placeholder="contentLocale.placeholderpnfl" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12" >
            <el-form-item prop="positionId" class="px-1 mb-3" :label="contentLocale.position">
              <el-select  v-model="postEmployees.positionId" :placeholder="contentLocale.position" class="w-100">
                <el-option
                    v-for="item in positionList "
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    :hidden="item.isDeleted"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="salaryByState" class="px-1 mb-3" :label="contentLocale.salarybystate">
              <el-input v-model="postEmployees.salaryByState"
                        @input="postEmployees.salaryByState = inputNumberType(postEmployees.salaryByState)"
                        :placeholder="contentLocale.salarybystate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item  class="px-1 mb-3" :label="contentLocale.birthdate">
              <el-date-picker
                  v-model="postEmployees.birthDate"
                  :placeholder="contentLocale.placeholderbirthdate" class="w-100"
                  :picker-options="{
                    disabledDate(date) { return date > new Date() }
                  }"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="employmentDate" class="px-1 mb-3" :label="contentLocale.employmentdate">
              <el-date-picker  v-model="postEmployees.employmentDate" :placeholder="contentLocale.placeholderemploymentdate" class="w-100"></el-date-picker>
            </el-form-item>

          </el-col>
          <el-col :md="12" v-if="!isUpdate">
            <el-form-item prop="startSchedule" class="px-1 mb-3" :label="contentLocale.startschedule">
              <el-date-picker  v-model="postEmployees.startSchedule" :placeholder="contentLocale.startschedule" class="w-100"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="dialog-footer">
      <el-button v-if="isUpdate" type="success" @click="save"> {{ contentLocale.edit }}</el-button>
      <el-button v-else type="success" @click="save"> {{ contentLocale.save }}</el-button>
      <el-button @click="upsertModal = false" type="info">{{ contentLocale.cancel }}</el-button>
    </div>
    </el-dialog>
    <el-dialog
        :title="postEmployees.firstName + ' ' + ' ' + postEmployees.lastName + ' ' + postEmployees.middleName"
        :visible="deleteModal"
        width="33%"
        @close="deleteModal= false"
        :close-on-click-modal="true"
        :show-close="false"
        class="my-modal"
    >
      <div class="modal-content">
        {{ contentLocale.wantdelete }}?
      </div>
      <div class="dialog-footer">
        <el-button type="success" @click="deleteItem"> {{ contentLocale.yes }}</el-button>
        <el-button @click="deleteModal = false" type="info">{{ contentLocale.no }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Employees",
  data() {
    return {
      EmployeesGroupList: [],
      isTableLoading: false,
      upsertModal:false,
      isUpdate:false,
      deleteModal:false,
      paginationList: {
        pageNumber: 1,
        pageSize: 10,
        from: null,
        to: null,
        birthDateFilter: {from: null, to: null},
        positionIds: null,
        salaryFrom: null,
        salaryTo: null,
        farmId:null
      },

      exSalaryFrom: null,
      exSalaryTo: null,

      birthdateMassive:[],
      totalCount:0,
      postEmployees: {
        id: 0,
        firstName: "",
        lastName: "",
        middleName: "",
        address: "",
        phoneNumber: "",
        pinfl: "",
        birthDate: "",
        positionId: null,
        employmentDate: "",
        salaryByState: null,
        farmId:null,
        startSchedule:new Date()
      },
      formRules:{
        firstName: { required: true, message: 'Илтимос, номини киритинг', trigger: 'blur, change' },
        lastName: { required: true, message: 'Илтимос, фамилиянгизни киритинг', trigger: 'blur, change' },
        middleName: { required: true, message: 'Илтимос, отасининг исмни киритинг', trigger: 'blur, change' },
        positionId: { required: true, message: 'Илтимос, позицияни танланг', trigger: 'blur, change' },
        startSchedule: { required: true, message: 'Илтимос, startSchedule  танланг', trigger: 'blur, change' },
        employmentDate: { required: true, message: 'Илтимос, ишга жойлашиш санасини киритинг', trigger: 'blur, change' },
        salaryByState: { required: true, message: 'Давлат томонидан иш ҳақи киритинг', trigger: 'blur, change' },
        farmId: { required: true, message: 'Илтимос, фермани танланг', trigger: ['blur, change'] },

      },
      positionList:[],
      isSearch: false,
      farms:[]
    }
  },
  methods: {
    tableRowClassName({row}){
      if (row){
        return row.isDeleted ? 'delete-effect': '';
      }
    },

    // disabledDate(date){
    //   return date > new Date();
    // },
    getBirthdate(){
      this.paginationList.birthDateFilter.from = this.birthdateMassive[0];
      this.paginationList.birthDateFilter.to = this.birthdateMassive[1];
      console.log(this.birthdateMassive)
    },
    searchList(){
      this.exSalaryFrom?.length > 0 ? this.paginationList.salaryFrom = this.exSalaryFrom.replace(/\s/g, '') : null;
      this.exSalaryTo?.length > 0 ? this.paginationList.salaryTo = this.exSalaryTo.replace(/\s/g, '') : null;
      if (
          this.paginationList.from ||
          this.paginationList.to ||
          this.paginationList.birthDateFilter.from ||
          this.paginationList.birthDateFilter.from ||
          this.paginationList.positionIds?.length ||
          this.paginationList.salaryFrom ||
          this.paginationList.salaryTo ||
          this.paginationList.farmId
      ){
        this.paginationList.pageNumber = 1;
        this.EmployeesList();
        this.isSearch = true;
      } else {
        this.isButtonDisabled = true;
      }
    },
    resetSearchList(){
      this.paginationList.from = null;
      this.paginationList.to = null;
      this.paginationList.birthDateFilter.from = null;
      this.paginationList.birthDateFilter.to = null;
      this.birthdateMassive = [];
      this.paginationList.positionIds = null;
      this.paginationList.salaryFrom = null;
      this.paginationList.salaryTo = null;
      this.exSalaryFrom = null;
      this.exSalaryTo = null;
      this.paginationList.farmId = null;
      this.EmployeesList();
      this.isSearch = false;
    },

    handleSizeChange(val){
      this.paginationList.pageSize = val;
      this.EmployeesList();
    },
    handleCurrentChange(val){
      this.paginationList.pageNumber = val;
      this.EmployeesList();
    },
    addShowModal(){
     if (this.$refs.myForm){
       this.$refs.myForm.resetFields();
       this.postEmployees = {};
     }
     this.isUpdate = false;
     this.upsertModal = true},
    async showEditModal(id){
      await this.getEmployee(id);
      this.isUpdate = true;
      this.upsertModal = true;
    },
    showDeleteModal(id,data){
     this.postEmployees.id = id;
     this.postEmployees.firstName = data.firstName;
      this.postEmployees.lastName = data.lastName;
      this.postEmployees.middleName = data.middleName;
     this.deleteModal = true;
    },

    async save(){
      await this.$refs.myForm.validate(valid => {
        if (valid){
          try {
            this.postEmployees.salaryByState.length > 0 ? this.postEmployees.salaryByState = this.postEmployees.salaryByState.replace(/\s/g, '') : null;
            this.postEmployees.birthDate = this.postEmployees.birthDate ? this.$moment(this.postEmployees.birthDate).format('YYYY-MM-DD') : null;
            this.postEmployees.employmentDate = this.postEmployees.employmentDate ? this.$moment(this.postEmployees.employmentDate).format('YYYY-MM-DD') : null;
            this.postEmployees.startSchedule = this.postEmployees.startSchedule ? this.$moment(this.postEmployees.startSchedule).format('YYYY-MM-DD') : null
            this.$http.post('Employees/upsert', this.postEmployees)
                .then(res=> {
                  if (res && res.success){
                    this.EmployeesList();
                    this.successNotify();
                    this.upsertModal = false;
                  }


                })
          } catch(e) {
            this.errorNotify(this.contentLocaleBack[e?.response?.data?.error?.message?.toLowerCase()])
          }
        }
      })
    },

    async getEmployee(id){
      await this.$http.get(`Employees/${id}`).then(res=>{
            this.postEmployees.id = res.result.id;
            this.postEmployees.firstName = res.result.firstName;
            this.postEmployees.lastName = res.result.lastName;
            this.postEmployees.middleName = res.result.middleName;
            this.postEmployees.address = res.result.address;
            this.postEmployees.phoneNumber = res.result.phoneNumber;
            this.postEmployees.pinfl = res.result.pinfl;
            this.postEmployees.birthDate = res.result.birthDate;
            this.postEmployees.positionId = res.result.positionId;
            this.postEmployees.employmentDate = res.result.employmentDate;
            this.postEmployees.salaryByState = res.result.salaryByState;
            this.postEmployees.farmId = res.result.farmId;
            this.postEmployees.startSchedule = res.result.startSchedule
          })
          .catch(e=>{
            this.errorNotify(this.contentLocaleBack[e?.response?.data?.error?.message?.toLowerCase()])
          })
    },
    async deleteItem(){
      await this.$http.delete(`Employees/${this.postEmployees.id}`)
          .then(res=>{
            this.EmployeesGroupList = res.result.data;
            this.totalCount = res.result.totalCount;
            this.successNotify();
            this.deleteModal = false;
          })
          .catch(e=>{
            this.errorNotify(this.contentLocaleBack[e?.response?.data?.error?.message?.toLowerCase()])
          })
    },
    getFarmIdByName(id){
      let item = this.farms.find(x=> x.id === id);
      return item ? item.name : id;
    },
    async EmployeesList(){
        this.paginationList.positionIds = this.paginationList.positionIds?.length > 0 ? this.paginationList.positionIds : null;
        this.paginationList.salaryFrom = this.paginationList.salaryFrom?.length > 0 ? this.paginationList.salaryFrom : null;
        this.paginationList.salaryTo = this.paginationList.salaryTo?.length > 0 ? this.paginationList.salaryTo : null;
        this.paginationList.to =this.paginationList.to ?  this.$moment(this.paginationList.to).format('YYYY-MM-DD'):null;
      this.paginationList.farmId = this.paginationList.farmId?.length > 0 ? this.paginationList.farmId : null;
      try {
        this.isTableLoading = true;
        await this.$http.post(`/Employees`, this.paginationList).then((res) => {
          this.EmployeesGroupList = res.result.data;
          this.totalCount = res.result.totalCount;
        })
      }catch (e){
        this.errorNotify(this.contentLocaleBack[e?.response?.data?.error?.message?.toLowerCase()]);
      }finally {
        this.isTableLoading = false;
      }
    }
  },
  watch:{
    '$i18n.locale': function (val){
      this.EmployeesList();
    },
  },
  computed:{
    weightTypes(){
      return [
          {id:0, name: this.contentLocale.kg},
        {id:1, name: this.contentLocale.liter}
      ]
    }
  },
  created() {
    this.EmployeesList();
    this.getSelectList('/EmployeePositions', this.positionList);
    this.getSelectList('/Farms',this.farms)
  }
}
</script>

<style scoped>
@media screen and (min-width : 1200px) {
  #first-display-btn {
      display: none;
  }
  #second-display-btn {
      display: block;
  }
}
@media screen and (max-width: 1201px) {
    #second-display-btn {
        display: none;
    }
    #first-display-btn {
        display: block;
    }
  }
  >>>.el-table th.el-table__cell:nth-child(8) {
    text-align: right;
  }
  >>>.el-table__row.delete-effect td{
    -webkit-box-shadow: 0px 0px 4px 5px rgba(227, 0, 0, 0.24) inset;
    -moz-box-shadow: 0px 0px 4px 5px rgba(227, 0, 0, 0.24) inset;
    box-shadow: 0px 0px 4px 5px rgba(227, 0, 0, 0.24) inset;
  }
</style>
