<template>
<div class="front-view">
  <SideNav/>
</div>
</template>

<script>
import SideNav from "@/components/SideNav/SideNav";
export default {
  name: "FrontLayout",
  components:{
    SideNav
  }
}
</script>

<style scoped>

</style>