import Vue from 'vue';
import Moment from 'moment';


Vue.filter('commonDateFormat', (val) => {
    return Moment(val).isValid() ? Moment(val).format('DD.MM.YYYY HH:mm') : '';
});
Vue.filter('commonDateFormatYear', (val) => {
    return Moment(val).isValid() ? Moment(val).format('DD.MM.YYYY') : '';
});
Vue.filter('dateMonthYearFormatter', (val) => {
    return Moment(val).isValid() ? Moment(val).format('DD MMM')+ ', ' + Moment(val).format('YYYY') : '';
});
Vue.filter('dateOnlyTime', (val) => {
    return Moment(val).isValid() ? Moment(val).format('HH:mm') : '';
});
Vue.filter('differentDay', function (value, different) {
    let a = Moment(value);
    let b = Moment(different);
    return (a.diff(b, 'days'));
});
Vue.filter('financialFormatMoney', function (value) {
    if (value){
        let val = (value/1).toFixed(2)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }
    else return 0;
});
Vue.filter('financialFormatNumber', function (value) {
    if (value){
        let val = (value/1).toFixed(0)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }
    else return 0;

});
Vue.filter('financialFormatCent', function (value) {
    if (value){
        let val = value.toFixed(2)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }
    else return 0;

});
Vue.filter('financialPhoneNumber', function (value) {
    if (value){
        let numericValue = value.replace(/[^0-9]/g, '');
        if (numericValue.length === 9) {
            return numericValue.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
        }
        if (numericValue.length === 12){
            return numericValue.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
        } else {
            return value;
    }

    // Remove any non-numeric characters from the input

    }});