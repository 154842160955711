const can = function (perm) {
    let permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : [];
    let access = false;
    permissions.forEach(item => {
        if (item === perm) {
            return access = true;
        }
    });
    return access;
};

// const can = () => true

export default can;