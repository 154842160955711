import Vue from 'vue'
import Vuex from 'vuex';
import http from "@/plugins/axios";
Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        notification: false,
        notificationType: null,
        notificationText: null,
        localization:[],
        localizationBackend:[]
    },
    mutations:{
        changeNotification(state, value) {
            state.notification = value;
        },
        changeNotificationType(state, type) {
            state.notificationType = type;
        },
        changeNotificationText(state, text) {
            state.notificationText = text;
        },
        changeLocale(state, locale) {
            state.localization = locale;
        },
        changeLocaleBack(state, locale) {
            state.localizationBackend = locale;
        },
    },
    getters:{
        getLocale(state){
            return state.localization
        },
        getContentLocale(state, key) {
            return state.localization ? state.localization : [];
        },
        getLocaleBack(state){
            return state.localizationBackend
        },
        getContentLocaleBack(state, key) {
            return state.localizationBackend ? state.localizationBackend : [];
        },

    },
    actions:{
        async getValuesByLang(vuexContext) {
            let val = 'front_end';
            return await http.get(`Anonymous/getLocalizedValues?groupKeyword=${val}`);
        },
        async getValuesBack(){
            let val = 'responsecode';
            return await http.get(`Anonymous/getLocalizedValues?groupKeyword=${val}`);
        }
    }
})